import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";

const BlogCard = ({ title, image, uri, date, imageAlt, type }) => {
	return (
		<Col md={6} xl={4} lg={5}>
			<Card
				as={Link}
				to={`/blog${uri}`}
				className="w-100 mb-3 mb-md-4 px-4 border-0"
			>
				<Card.Img
					as={GatsbyImage}
					variant="top"
					style={{ maxHeight: "228px", width: "100%", minHeight: "228px" }}
					image={image}
					alt={imageAlt}
				/>
				<Card.Body className="ps-0 ms-0">
					<Card.Text className="pb-0 mb-1">
						<p className="fw-bold text-primary mb-0 pb-0">{type}</p>
					</Card.Text>
					<Card.Title className="d-flex text-secondary ps-0 ms-0">
						{title}
					</Card.Title>
					<Card.Text>
						<p className="text-secondary">{date}</p>
					</Card.Text>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default BlogCard;
