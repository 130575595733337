import React from "react";
import BlogCard from "../../components/blogCard";
import Layout from "../../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutHero from "../../components/about-hero";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const BlogPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: wpMediaItem(title: { eq: "Blog-Hero" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				blogPosts: allWpPost(
					sort: { fields: dateGmt, order: DESC }
					filter: { title: { ne: "Website Images" } }
				) {
					nodes {
						date(formatString: "MMMM DD, YYYY")
						id
						title
						uri
						excerpt
						blogFields {
							featuredImage {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
							type
						}
					}
				}
			}
		`
	);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Blog Page | ARC Writing and Translation Services"
				description="Latest blog posts for ARC Writing and Translation Services."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/blog`,
					title: "Blog Page | ARC Writing and Translation Services",
					description:
						"Latest blog posts for ARC Writing and Translation Services.",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/j3R3HV6g-scaled-1-683x1024-1.jpg`,
							width: 683,
							height: 1024,
							alt: "ARC Writing and Translation Services blog page",
						},
					],
				}}
			/>
			<section>
				<AboutHero
					h="h-100 h-md-85"
					imgWidth="25rem"
					hCenter={true}
					title={<span className=" mt-4 mt-md-0 ms-lg-5 ms-xl-0">Blog</span>}
					description={
						<hr
							className="bg-secondary mt-0 mb-5 ms-lg-5 ms-xl-0 mx-auto mx-md-0 mb-md-0"
							style={{ height: "3px", width: "100px" }}
						/>
					}
					image={heroImg}
					imgAlt={data.heroImg.altText}
				/>
				<Container as="section" className="mt-4 mt-md-0">
					<Row className="mb-5">
						<Col xs={12}>
							<h2 className=" underline  ">Previous blog posts</h2>
						</Col>
					</Row>

					<Row className="justify-content-center justify-content-xl-start">
						{data.blogPosts.nodes.map((post) => (
							<BlogCard
								date={post.date}
								type={post.blogFields.type}
								key={post.id}
								title={post.title}
								uri={post.uri}
								image={
									post.blogFields.featuredImage?.localFile?.childImageSharp
										?.gatsbyImageData
								}
								imageAlt={post.blogFields.featuredImage?.altText}
							></BlogCard>
						))}
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPage;
